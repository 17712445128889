"use client"
import Image from "next/image"
import {QueryClient, QueryClientProvider} from "@tanstack/react-query"
import {useState} from "react"
// import "../../output.css"

export default function GoLayout({children}: {children: React.ReactNode}) {
    const [queryClient] = useState(() => new QueryClient())
    
    return (
        <QueryClientProvider client={queryClient}>
            <div className="auth-bg flex min-h-screen flex-col">
                <Image src="/images/auth-pattern.svg" alt="CreditCaptain Auth Pattern" role="presentation" width={348} height={311} className="pointer-events-none absolute right-0 top-0 h-auto w-[348px]" priority />

                <div className="grid flex-1 flex-col lg:grid-cols-1">
                    <div className="flex flex-1 flex-col items-center justify-center py-3 lg:pr-0">
                        <main className="flex flex-1 flex-col rounded-2xl bg-white p-3 lg:p-0 overflow-hidden shadow-action ring-1 ring-neutral-200 w-[calc(100vw-20px)]">
                            {children}
                        </main>
                    </div>
                </div>
            </div>
        </QueryClientProvider>
    )
}
